/**
 *
 * You can write your JS code here, DO NOT touch the default style file
 * because it will make it harder for you to update.
 * 
 */


'use strict';
$(function () {
  //CKEditor
  CKEDITOR.replace('ckeditor');
  CKEDITOR.config.height = 300;

  if (window.CodeMirror) {
    $(".codeeditor").each(function () {
      let editor = CodeMirror.fromTextArea(this, {
        lineNumbers: true,
        theme: "duotone-dark",
        mode: 'javascript',
        height: 200
      });
      editor.setSize("100%", 200);
    });
  }

});


// $(document).ready(function() {

//  $('.customDataTable').DataTable();
//  });
/*** Fixed Top ***/
$(function () {
var fixmeTop = $('.fixedTop').offset().top;       // get initial position of the element

$(window).scroll(function() {                  // assign scroll event listener

    var currentScroll = $(window).scrollTop(); // get current position

    if (currentScroll >= fixmeTop) {           // apply position: fixed if you
        $('.fixedTop').css({                      // scroll to that element or below it
            position: 'sticky',
            top: '30px',
        });
    } else {                                   // apply position: static
        $('.fixedTop').css({                      // if you scroll above it
            position: 'relative',
            top: '0px',
        });
    }
});
});
/*** Fixed Top End ***/
  $('.dropdown-menu').on('click', function(event) {
    event.stopPropagation();
  });

/*** 
  Chat Widget
***/
// function receiveMessage(e){var t=JSON.parse(e.data),i=t.status_style;"openChat"!=t.operation_type?document.getElementById("ChatFrame").setAttribute("style","position: fixed; right: 0px; bottom: 0px; border: none;height:200px;width:200px;"):document.getElementById("ChatFrame").setAttribute("style",i)}$(document).ready(function(){$("body").append("<iframe src='https://omni.mconnectapps.com/webchat/?aid=NjQ=&wid=SVRGUw==' id='ChatFrame' style='position: fixed; right: 0px; bottom: 0px; border: none;height:200px;width:200px;'></iframe>")}),window.addEventListener("message",receiveMessage,!1);
function receiveMessage(e){var t=JSON.parse(e.data),i=t.status_style;"openChat"!=t.operation_type?document.getElementById("ChatFrame").setAttribute("style","position: fixed; right: 0px; bottom: 0px; border: none;height:45px;width:275px;"):document.getElementById("ChatFrame").setAttribute("style",i)}$(document).ready(function(){$("body").append("<iframe src='https://ticketing.mconnectapps.com/webbot/?aid=NjQ=&wid=Q29tbXVuaXR5IENlbnRlcg==' id='ChatFrame' style='position: fixed; right: 0px; bottom: 0px; border: none;height:45px;width:275px;'></iframe>")}),window.addEventListener("message",receiveMessage,!1);

/***
  Chat Widget End
***/
'use strict';
$(function () {
    // chart1(ASA);
 
});

function chart1(datas,data2,datascolor) {
    console.log(datas)
    console.log(data2)

       var countdata =[];
       var timedata =[];
       timedata = data2
       countdata = datas
//        var count =[];
// for (var i = 0; i < data.length; i++) {
//     data[i].valcount =data[i].count
//   countdata.push({
//       name: data[i].event_name,
//       data: [{
//           x:data[i].start_time,
//           y:data[i].valcount
//       }]
//   })
//   timedata.push(data[i].start_time)
// }
// console.log(timedata)
// console.log(countdata)
    var options = {

                colors:datascolor,
        // colors : ['#4f8bb7', '#65a986', '#c554bc', '#d68e41','#bc4947','#df940b', '#1ba747','#cece01'],
        chart: {
            height: 350,
            type: 'bar',

        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        series: 
        countdata,
        // [{
        //     name: 'Swimming',
        //     data: [8, 0, 30, 0, 31, 0, 0, 0, 15]
        // }, {
        //     name: 'Hockey',
        //     data: [10, 32, 0, 34, 0, 0, 0, 28, 0]
        // }, {
        //     name: 'Basketball',
        //     data: [0, 25, 0, 26, 0, 0, 0, 15, 0]
        // }, {
        //     name: 'Football',
        //     data: [15, 25, 0, 6, 26, 0, 18, 0, 18]
        // }, {
        //     name: 'Tennis',
        //     data: [25, 5, 16, 0, 32, 15, 0, 15, 18]
        // }, {
        //     name: 'Badminton',
        //     data: [12, 0, 34, 0, 12, 0, 14, 0, 32]
        // }, {
        //     name: 'Athletics',
        //     data: [24, 34, 0, 36, 0, 24, 0, 10, 8]
        // }, {
        //     name: 'Skateboarding',
        //     data: [12, 0, 16, 10, 0, 16, 0, 4, 5]
        // }


        // ]
        // ,
        xaxis: {
            categories: 
            // ['9AM', '10AM', '11AM', '12PM'],

            timedata,
            // ['9AM', '10AM', '11AM', '12PM', '1PM', '2PM', '3PM', '4PM'],
            labels: {
                style: {
                    colors: '#9aa0ac',
                }
            }
        },
        yaxis: {
            title: {
                text: 'Student Count'
            },
            labels: {
                style: {
                    color: '#9aa0ac',
                }
            }
        },
        fill: {
            opacity: 1

        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "" + val + ""
                }
            }
        }
    }

    var chart = new ApexCharts(
        document.querySelector("#chart1"),
        options
    );

    chart.render();


}

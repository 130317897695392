
var today = new Date();
year = today.getFullYear();
month = today.getMonth();
day = today.getDate();
var calendar = $('#myEvent').fullCalendar({
  height: 'auto',
  defaultView: 'month',
  editable: true,
  selectable: true,
  header: {
    left: 'prev,next today',
    center: 'title',
    right: 'month,agendaWeek,agendaDay,listMonth'
  },
  events: [{
    title: "Basketball - Manikandan",
    start: new Date(year, month, day, 9, 0),
    end: new Date(year, month, day, 11, 0),
    backgroundColor: "#be07be"
  }, {
    title: "Hockey - Desla",
    start: new Date(year, month, day, 10, 30),
    end: new Date(year, month, day, 12, 30),
    backgroundColor: "#65a986"
  }, {
    title: "Athletics - Lorenz",
    start: new Date(year, month, day, 11, 30),
    end: new Date(year, month, day, 13, 30),
    backgroundColor: "#1ba747"
  }, {
    title: "Football - Adam",
    start: new Date(year, month, day, 13, 30),
    end: new Date(year, month, day, 15, 30),
    backgroundColor: "#d68e41"
  },
  {
    title: "Badminton - Chris Moris",
    start: new Date(year, month, day, 13, 30),
    end: new Date(year, month, day, 15, 30),
    backgroundColor: "#df940b"
  },
  {
    title: "Swimming - Andrew",
    start: new Date(year, month, day, 15, 30),
    end: new Date(year, month, day, 17, 30),
    backgroundColor: "#4f8bb7",
  },
   {
    title: "Tennis - Andrea",
    start: new Date(year, month, day, 14, 0),
    end: new Date(year, month, day, 16, 0),
    backgroundColor: "#bc4947"
  }, 

{
    title: "Skateboarding - Rashid",
    start: new Date(year, month, day + 1, 9, 0),
    end: new Date(year, month, day + 1, 11, 0),
    backgroundColor: "#cece01",
  },

   {
    title: "Football - Adam",
    start: new Date(year, month, day + 1, 10, 0),
    end: new Date(year, month, day + 1, 12, 0),
    backgroundColor: "#d68e41",
  }, {
    title: "Swimming - Andrew",
    start: new Date(year, month, day + 1, 11, 0),
    end: new Date(year, month, day + 1, 13, 0),
    backgroundColor: "#4f8bb7",
  },
  {
    title: "Athletics - Lorenz",
    start: new Date(year, month, day + 1, 13, 30),
    end: new Date(year, month, day + 1, 15, 30),
    backgroundColor: "#1ba747"
  },
  {
    title: "Badminton - Chris Moris",
  start: new Date(year, month, day + 1, 15, 30),
    end: new Date(year, month, day + 1, 17, 30),
    backgroundColor: "#df940b"
  },

  {
    title: "Hockey - Desla",
    start: new Date(year, month, day + 2, 09, 00),
    end: new Date(year, month, day + 2, 11, 30),
    backgroundColor: "#65a986",
  }, {
    title: "Tennis - Andrea",
    start: new Date(year, month, day + 2, 11, 00),
    end: new Date(year, month, day + 2, 13, 00),
    backgroundColor: "#bc4947",
  },
  {
    title: "Athletics - Lorenz",
    start: new Date(year, month, day + 2, 13, 30),
    end: new Date(year, month, day + 2, 15, 30),
    backgroundColor: "#1ba747"
  },
  {
    title: "Skateboarding - Rashid",
  start: new Date(year, month, day + 2, 15, 30),
    end: new Date(year, month, day + 2, 17, 30),
    backgroundColor: "#cece01"
  },


   {
    title: "Hockey - Desla",
    start: new Date(year, month, day + 3, 9, 30),
    end: new Date(year, month, day + 3, 11, 30),
    backgroundColor: "#65a986"
  }, 

{
    title: "Athletics - Lorenz",
    start: new Date(year, month, day + 3, 11, 30),
    end: new Date(year, month, day + 3, 13, 30),
    backgroundColor: "#1ba747"
  },
   {
    title: "Badminton - Chris Moris",
  start: new Date(year, month, day + 3, 14, 30),
    end: new Date(year, month, day + 3, 16, 30),
    backgroundColor: "#df940b"
  },


    {
    title: "Football - Adam",
    start: new Date(year, month, day + 4, 09, 00),
    end: new Date(year, month, day + 4, 11, 30),
    backgroundColor: "#d68e41",
  }, {
    title: "Skateboarding - Rashid",
    start: new Date(year, month, day + 4, 11, 00),
    end: new Date(year, month, day + 4, 13, 00),
    backgroundColor: "#cece01",
  },
  {
    title: "Athletics - Lorenz",
    start: new Date(year, month, day + 4, 13, 30),
    end: new Date(year, month, day + 4, 15, 30),
    backgroundColor: "#1ba747"
  },
  {
    title: "Badminton - Chris Moris",
  start: new Date(year, month, day + 4, 15, 30),
    end: new Date(year, month, day + 4, 17, 30),
    backgroundColor: "#df940b"
  },

  {
    title: "Basketball - Manikandan",
    start: new Date(year, month, day + 5, 2, 30),
    end: new Date(year, month, day + 5, 3, 00),
    backgroundColor: "#be07be"
  }, {
    title: "Athletics - Lorenz",
    start: new Date(year, month, day + 7, 17, 30),
    end: new Date(year, month, day + 7, 18, 00),
    backgroundColor: "#1ba747"
  }, {
    title: "Swimming - Andrew",
    start: new Date(year, month, day + 5, 9, 30),
    end: new Date(year, month, day + 5, 10, 00),
    backgroundColor: "#4f8bb7"
  }]
});

/*$("#myEvent").fullCalendar({
  height: 'auto',
  header: {
    left: 'prev,next today',
    center: 'title',
    right: 'month,agendaWeek,agendaDay,listWeek'
  },
  editable: true,
  events: [
    {
      title: 'Conference',
      start: '2018-01-9',
      end: '2018-01-11',
      backgroundColor: "#fff",
      borderColor: "#fff",
      textColor: '#000'
    },
    {
      title: "John's Birthday",
      start: '2018-01-14',
      backgroundColor: "#007bff",
      borderColor: "#007bff",
      textColor: '#fff'
    },
    {
      title: 'Reporting',
      start: '2018-01-10T11:30:00',
      backgroundColor: "#f56954",
      borderColor: "#f56954",
      textColor: '#fff'
    },
    {
      title: 'Starting New Project',
      start: '2018-01-11',
      backgroundColor: "#ffc107",
      borderColor: "#ffc107",
      textColor: '#fff'
    },
    {
      title: 'Social Distortion Concert',
      start: '2018-01-24',
      end: '2018-01-27',
      backgroundColor: "#000",
      borderColor: "#000",
      textColor: '#fff'
    },
    {
      title: 'Lunch',
      start: '2018-01-24T13:15:00',
      backgroundColor: "#fff",
      borderColor: "#fff",
      textColor: '#000',
    },
    {
      title: 'Company Trip',
      start: '2018-01-28',
      end: '2018-01-31',
      backgroundColor: "#fff",
      borderColor: "#fff",
      textColor: '#000',
    },
  ]

});
*/